<template>
    <CModal
        :show.sync="popupModalProp.isShow"
        :closeOnBackdrop="false"
        class="modal-fullscreen">
        <!-- Header -->
		<template #header>
			<h5 class="modal-title">Lembar Disposisi {{dispositionOwner}}</h5>
            <CButtonClose @click="closePopupModal()" class="text-gray"/>
		</template>
        <!-- Body -->
        <div class="p-1" id="myDiv">
            <div v-if="dispositionForm.receiverOptions.title != undefined">
                <label class="typo__label">Kepada Yth <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
                <CRow>
                    <CCol>
                        <!-- Parent -->
                        <div
                            v-for="position in dispositionForm.receiverOptions.positions" :key="position.position_id"
                            role="group"
                            class="custom-control custom-checkbox">
                            <input
                                v-model="dispositionForm.input.selectedPositions"
                                class="custom-control-input"
                                :disabled="!position.user"
                                :id="'position_'+ position.position_id"
                                type="checkbox"
                                :value="position"
                                @change="validateForm('receivers')" />
                            <label class="custom-control-label" :for="'position_'+ position.position_id">{{ position.position }}</label>
                            <!-- Children -->
                            <div
                                v-for="child in position.child" :key="child.position_id"
                                role="group"
                                class="custom-control custom-checkbox">
                                <input
                                    v-model="dispositionForm.input.selectedPositions"
                                    :class="'custom-control-input position_pid_'+ child.position_pid"
                                    :disabled="!child.user"
                                    :id="'position_'+ child.position_id"
                                    type="checkbox"
                                    :value="child"
                                    @change="validateForm('receivers')" />
                                <label class="custom-control-label" :for="'position_'+ child.position_id">{{ child.position }}</label>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </div>
            <div v-else class="mb-10">
                <label class="typo__label">Kepada Yth <small class="d-inline-block form-text text-danger">(*) wajib diisi</small></label>
                <CRow>
                    <CCol md="4">
                        <div
                            v-for="vipPosition in dispositionForm.receiverOptions.position_yth" :key="vipPosition.position_id"
                            role="group"
                            class="custom-control custom-checkbox">
                            <input
                                v-model="dispositionForm.input.selectedPositions"
                                class="custom-control-input"
                                :disabled="!vipPosition.user"
                                :id="'position_'+ vipPosition.position_id"
                                type="checkbox"
                                :value="vipPosition"
                                @change="validateForm('receivers')" />
                            <label class="custom-control-label" :for="'position_'+ vipPosition.position_id">{{ vipPosition.position }}</label>
                        </div>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol md="4" v-for="positionGroup in dispositionForm.receiverOptions.positions" :key="positionGroup.title">
                        <label class="typo__label">{{ positionGroup.title }}</label>
                        <!-- Parent -->
                        <div
                            v-for="position in positionGroup.position" :key="position.position_id"
                            role="group"
                            class="custom-control custom-checkbox">
                            <input
                                v-model="dispositionForm.input.selectedPositions"
                                class="custom-control-input"
                                :disabled="!position.user"
                                :id="'position_'+ position.position_id"
                                type="checkbox"
                                :value="position"
                                @change="validateForm('receivers')" />
                            <label class="custom-control-label" :for="'position_'+ position.position_id">{{ position.position }}</label>
                            <!-- Children -->
                            <div
                                v-for="child in position.child" :key="child.position_id"
                                role="group"
                                class="custom-control custom-checkbox">
                                <input
                                    v-model="dispositionForm.input.selectedPositions"
                                    :class="'custom-control-input position_pid_'+ child.position_pid"
                                    :disabled="!child.user"
                                    :id="'position_'+ child.position_id"
                                    type="checkbox"
                                    :value="child"
                                    @change="validateForm('receivers')" />
                                <label class="custom-control-label" :for="'position_'+ child.position_id">{{ child.position }}</label>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </div>
            <hr>
            <div class="mb-10">
                <label class="typo__label">Petunjuk</label>
                <CRow>
                    <CCol md="4" v-for="(commandChunk, ccindex) in dispositionForm.commandOptions" :key="ccindex">
                        <div
                            v-for="(command, index) in commandChunk" :key="command"
                            role="group"
                            class="custom-control custom-checkbox">
                            <input
                                v-model="checkedCommands"
                                class="custom-control-input"
                                :id="'command_'+ ccindex + index"
                                type="checkbox"
                                :value="command"
                                @change="clickCommand" />
                            <label class="custom-control-label" :for="'command_'+ ccindex + index">{{ command }}</label>
                        </div>
                    </CCol>
                </CRow>
            </div>
            <CTextarea
                v-model="dispositionForm.input.content"
                label="Catatan"
                placeholder="Catatan"
                rows="5"/>
        </div>
        <!-- Footer -->
        <template #footer>
			<CButton @click="closePopupModal()" class="btn-sm">Batal</CButton>
            <CButton @click="save()" color="success" class="btn-sm text-white" :disabled="dispositionForm.submitBtn.disabled">
                <font-awesome-icon v-if="dispositionForm.submitBtn.loader" icon="spinner" spin/> {{ dispositionForm.submitBtn.text }}
            </CButton>
		</template>
	</CModal>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        popupModalProp: {
            type: Object,
            required: true
        },
        docIdProp: {
            type: Number,
            required: true
        },
        memoRecordIdProp: {
            type: Number,
            default: null
        },
        verificationIdProp: {
            type: Number,
            default: null
        },
        verificationPositionIdProp: {
            type: Number,
            default: null
        },
        ownDispoProp: {
            type: Boolean,
            default: null
        }
    },
    data () {
        return {
            dispositionForm: {
                receiverShow: false,
                receiverOptions: {},
                dispositionedReceivers: [],
                commandOptions: [
                    [
                        'ACC',
                        'Selesaikan',
                        'Tindak lanjuti',
                        'Untuk diketahui/Sebagai info',
                        'Untuk dilaksanakan sesuai ketentuan',
                        'Pelajari dan laporkan hal menonjol',
                        'Ikuti Perkembangannya',
                        'Persiapkan sebaik-baiknya',
                        'Koordinasikan',
                        'Monitor arahan Menhan'
                    ], [
                        'Simpan file',
                        'Menghadap',
                        'Bahan evaluasi',
                        'Buatkan jawaban',
                        'Buatkan saran',
                        'Buatkan tanggapan',
                        'Buatkan resume',
                        'Buatkan bahan',
                        'Undangan/Pertemuan harap diwakili',
                        'Undangan/Pertemuan tidak hadir'
                    ], [
                        'Undangan/Pertemuan acarakan',
                        'Fotokopi surat'
                    ]
                ],
                outgoingState: false,
                input: {
                    // Memo
                    docId: null,
                    content: null,
                    // Disposition
                    dispositionNumber: null,
                    selectedPositions: [],
                    receivers: [],
                    selectedCommands: [],
                    command: null,
                    memoRecordId: null
                },
                defaultValue: {
                    // Memo
                    docId: null,
                    content: null,
                    // Disposition
                    dispositionNumber: null,
                    selectedPositions: [],
                    receivers: [],
                    selectedCommands: [],
                    command: null,
                    memoRecordId: null
                },
                validate: {
                    receivers: false,
                    content: false
                },
                submitBtn: {
                    loader: false,
                    text: 'Kirim',
                    disabled: true
                }
            },
            loggedPosition: null,
            checkedCommands: [],
            stateStoreDispositionIsDone: false, 
            stateFinishDispositionIsDone: false,
            statePostCommentIsDone: false,
            temporerDispositionHit: false,
            badge: '',
            commandListId: [],
            listPositionDataCheckBox: [],
            listPetunjukIdCheckBox: [],
            dispositionOwner: 'adaan',
        }
    },
    computed: {
        ...mapState('auth', {
            loggedUserPosition: 'position',
            loggedUserPositionId: 'position_id',
		}),
    },
    watch: {
        docIdProp: function (val) {
            this.dispositionForm.input.docId = val
            this.dispositionForm.input.selectedPositions = []
            this.checkedCommands = []
            this.dispositionForm.input.content = ''
            if(val != 0) {
                this.getReceiverOptions()
            }
        },
        memoRecordIdProp: function (val) {
            this.dispositionForm.input.memoRecordId = val
        },
        verificationPositionIdProp: function (val) {
            //this.getReceiverOptions()
        },
        temporerDispositionHit: function(val) {
            if(val) {
              //  this.getDraftDisposition()
            }
        },
        stateStoreDispositionIsDone: function(val) {
            if(val) {
                this.checkIfShouldBeClose()
            }
        },
        stateFinishDispositionIsDone: function(val) {
            if(val) {
                this.checkIfShouldBeClose()
            }
        },
        statePostCommentIsDone: function(val) {
            if(val) {
                this.checkIfShouldBeClose()
            }
        }
    },
    mounted () {
        this.getReceiverOptions()
    },
    methods: {
        handleError(err) {
            var messageErr = err.response.data.error
            this.$toasted.error(messageErr).goAway(3000)
            this.dispositionForm.submitBtn = {
                loader: false,
                text: 'Kirim',
                disabled: false
            }

        },
        addListPetunjukCheckBox(){
            this.listPetunjukIdCheckBox = []
            for(var ccIndex = 0; ccIndex < this.dispositionForm.commandOptions.length; ccIndex++) {
                for(var index = 0; index < this.dispositionForm.commandOptions[ccIndex].length; index++) {
                    var checkBoxData = {
                        id: 'command_'+ccIndex+index,
                        label: this.dispositionForm.commandOptions[ccIndex][index]
                    }
                    this.listPetunjukIdCheckBox.push(checkBoxData)
                }
            }
        },
        // Options
        getReceiverOptions () {
            var x = document.getElementById('myDiv')
            x.style.display = "none"
            this.addListPetunjukCheckBox()
            let filter = {}
            if (this.verificationPositionIdProp != null && this.verificationIdProp != 0 && this.ownDispoProp != true)
                filter = { positionId: this.verificationPositionIdProp }

            this.$store.dispatch({
                type: 'disposition/getReceiver',
                filter: filter
            }).then( res => {
                const positionOptions = res.result
                this.dispositionOwner =  res.result.position_name
                this.dispositionForm.receiverOptions = positionOptions
                this.getListPositionDataCheckBox(positionOptions)
                this.getDispositionedReceivers()
            }).finally( () => {
                let disabledCheckbox = document.getElementById('position_'+ this.loggedUserPositionId)
                if (disabledCheckbox)
                    disabledCheckbox.disabled = true
                x.style.display = "block"
            })
        },

        getListPositionDataCheckBox(receiverOptions) {
            this.listPositionDataCheckBox = []

            if(receiverOptions.title != undefined) {
                receiverOptions.positions.forEach(position => {
                    this.listPositionDataCheckBox.push(position)
                })
            } else {
                receiverOptions.position_yth.forEach(position => {
                    this.listPositionDataCheckBox.push(position)
                })

                receiverOptions.positions.forEach(positionGroup => {
                    positionGroup.position.forEach(position => {
                        this.listPositionDataCheckBox.push(position)

                        if(position.child != null ) {
                            position.child.forEach(child=> {
                                this.listPositionDataCheckBox.push(child)
                            })
                        }
                    })
                })
            }
            console.log(this.listPositionDataCheckBox)
        },
        getDispositionedReceivers () {
            let docId = this.dispositionForm.input.docId
            if(docId != null) {
                this.$store.dispatch({
                    type: 'document/getDetailDocument',
                    pagination: {
                        limit: 1,
                        offset: 0
                    },
                    filter: {
                        docId: docId
                    }
                }).then( res => {
                    let data = res.result[0]
                    if(data!= undefined) {
                        if(data.temp_dispo_hit) {
                            this.temporerDispositionHit = data.temp_dispo_hit
                            if(this.temporerDispositionHit && data.badge == 'Verifikasi') {
                                // this.dispositionForm.submitBtn.text = "Simpan"
                                this.dispositionForm.submitBtn.text = "Kirim"
                            } else {
                                this.dispositionForm.submitBtn.text = "Kirim"
                            }
                        }
                        this.badge = data.badge
                        let dispositionedReceivers = data.already_disposition
                        if (dispositionedReceivers.length > 0) {
                            let dispositionedReceiverIds = []
                            for (let i = 0; i < dispositionedReceivers.length; i++) {
                                const item = dispositionedReceivers[i];
                                dispositionedReceiverIds.push(item.position_id)
                                var disabledCheckbox = document.getElementById('position_'+ item.position_id)
                                if (disabledCheckbox)
                                    disabledCheckbox.disabled = true
                            }
                            this.dispositionForm.dispositionedReceivers = dispositionedReceiverIds
                        }

                        this.getDraftDisposition()
                    }
                })
            }
        },
        saveCheckBoxIdCommands() {
            var data=[]
            for(var ccindex = 0; ccindex < this.dispositionForm.commandOptions.length; ccindex++) {
                for(var index=0; index < this.dispositionForm.commandOptions[ccindex]; index++) {
                    data.push(this.saveIndexCommands(this.dispositionForm.commandOptions[ccindex][index], ccindex, index))
                }
            }
            return data
        },
        saveIndexCommands(command, ccindex, index) {
            var elementId = 'command_'+ ccindex + index
            this.commandListId.push({
                value: command,
                id: elementId
            }) 
            return elementId
        },

        isForGetTemporer() {
            if(this.badge == 'Verifikasi' && this.temporerDispositionHit) {
                return true
            }

            if(this.badge == 'Disposisi' && this.temporerDispositionHit) {
                return true
            }

            return false
        },
        // Verification
        getDraftDisposition () {
            if(this.isForGetTemporer()) {
                this.$store.dispatch({
                    type: 'disposition/getTemporer',
                    filter: { docId: this.docIdProp }
                }).then( res => {
                    // Reset Checkboxes
                    var checkboxes = document.getElementsByTagName('input')
                    for (var i = 0; i < checkboxes.length; i++) {
                        if (checkboxes[i].type == 'checkbox') {
                            checkboxes[i].checked = false;
                        }
                    }
                    
                    if (res.result != null) {
                        const data = res.result[0]
                        // Checking positions
                        const positionIds = data.disposisi_position_id
                        const positionReceivers = this.dispositionForm.receiverOptions
                        this.dispositionForm.input.selectedPositions = []
                        positionIds.forEach(positionId => {
                            document.getElementById('position_'+positionId).checked = true
                            var selectedPosition = this.listPositionDataCheckBox.filter(data=> data.position_id  == positionId)[0]
                            if(selectedPosition == undefined) {
                                let dataList = this.listPositionDataCheckBox
                                console.log(dataList)
                            }
                           
                            this.dispositionForm.input.selectedPositions.push(selectedPosition)
                            
                        });

                        var dataLog = this.dispositionForm.input.selectedPositions
                        this.dispositionForm.input.receivers = data.disposisi_position_id
                        this.dispositionForm.input.selectedCommands = data.petunjuk
                        this.dispositionForm.input.content = data.catatan
                        this.validateForm('receivers')
                    
                        var listData = this.saveCheckBoxIdCommands()
                        data.petunjuk.forEach(item=>{
                            var selectedData = this.commandListId.find(obj=> { return obj.value = item})
                            console.log(selectedData)
                        })

                        //add Petunjuk yg ada
                        if(data.petunjuk !=  undefined) {
                            console.log(this.checkedCommands)
                            this.checkedCommands = [];
                            console.log(this.checkedCommands)
                            this.checkedCommands = data.petunjuk
                        }

                        //checkedPetunjuk

                        data.petunjuk.forEach(item => {
                            var commandCheckBoxData = this.listPetunjukIdCheckBox.filter(data=> data.label == item)[0]
                            var checkBoxHtmlData = document.getElementById(commandCheckBoxData.id)
                            checkBoxHtmlData.checked = true
                        })
                    }
                }).catch ( err => {
                    console.log('Failed to fetch draft disposition data')
                    console.log(err)
                })
            }
        },
        // Diposition Form Event Handlers
        clickReceiver(value, event) {
            let state = event.target.checked
            let children = value.child
            this.validateForm('receivers')
            if (children == undefined)
                return
            
            for (let i = 0; i < children.length; i++) {
                const childProp = children[i]
                if (state) {
                    this.dispositionForm.input.selectedPositions.push(childProp)
                } else {
                    let removeIndex = this.dispositionForm.input.selectedPositions.indexOf(childProp)
                    this.dispositionForm.input.selectedPositions.splice(removeIndex, 1)
                }
            }
        },
        clickCommand() {
            this.dispositionForm.input.selectedCommands = this.checkedCommands
            let commands = this.dispositionForm.input.selectedCommands.join(',')
            this.dispositionForm.input.command = commands
        },
        validateForm (field) {
            if (field == 'receivers') {
                if (this.dispositionForm.input.selectedPositions.length > 0 ||
                    this.dispositionForm.input.receivers.length > 0
                ) {
                    this.dispositionForm.validate.receivers = true
                } else {
                    this.dispositionForm.validate.receivers = false
                }
            }

            if (this.dispositionForm.validate.receivers) {
                this.dispositionForm.submitBtn.disabled = false
            } else {
                this.dispositionForm.submitBtn.disabled = true
            }
        },

        isForStoreTemporer(){
            if(this.badge == 'Verifikasi' && this.temporerDispositionHit && this.ownDispoProp != true) {
                return true
            }

            if(this.badge == 'Disposisi' && this.temporerDispositionHit) {
                return false
            }
            
            if(this.badge == 'Disposisi' && this.temporerDispositionHit == false) {
                return false
            }

            return false
        },
        // Process
        storeDisposition () {
            // Extract users from selected positions
            let storeType = 'disposition/store'
            let selectedPositions = this.dispositionForm.input.selectedPositions
            let receivers = []
            if (this.isForStoreTemporer()) {
                storeType = 'disposition/storeTemporer'
                for (let i = 0; i < selectedPositions.length; i++) {
                    const positionProp = selectedPositions[i]
                    receivers.push(positionProp.position_id)
                }
            } else {
                for (let i = 0; i < selectedPositions.length; i++) {
                    const positionProp = selectedPositions[i]
                    const positionUser = positionProp.user
                    receivers.push(positionUser.user_id)
                }
                this.dispositionForm.input.receivers = receivers
            }
            this.dispositionForm.input.command = this.dispositionForm.input.selectedCommands
         
            this.dispositionForm.input.receivers = receivers
            this.dispositionForm.input.docId = this.docIdProp
            this.$store.dispatch({
                type: storeType,
                formInput: this.dispositionForm.input
            }).then( res => {
                this.stateStoreDispositionIsDone = true
                this.checkIfShouldBeClose()
                console.log('Success to store disposition')
            }).catch( err => {
                console.log('Failed to store disposition')
                console.log(err)
                this.handleError(err)
            })
			
        },
        finishDisposition () {
			this.$store.dispatch({
				type: 'disposition/update',
				formInput: {
                    dispositionId: this.dispositionForm.input.memoRecordId,
                    isDone: true,
                    docId: this.docIdProp
                }
			}).then( res => {
                console.log('Success to update finish disposition')
                
                this.stateFinishDispositionIsDone = true
                this.checkIfShouldBeClose()
            }).catch( err => {
                console.log('Failed to update finish disposition')
                console.log(err)
                this.handleError(err)
            })
        },
        postComment (messageContent) {
            let commentFormInput = {
                doc_id: this.docIdProp,
                message: messageContent
            }
            this.statePostCommentIsDone = true
            this.checkIfShouldBeClose()
            //this.$store.dispatch({
            //    type: 'document/postComment',
            //    formInput: commentFormInput
            //}).then( res => {
            //    this.statePostCommentIsDone = true
            //    this.checkIfShouldBeClose()
            //    console.log('Success to post diposition comment '+ messageContent)
            //}).catch( err => {
            //    console.log('Failed to post disposition comment'+ messageContent)
            //    console.log(err)
            //    this.handleError(err)
            //})
        },
        async save () {
            // Start save process
            this.dispositionForm.submitBtn = {
                loader: true,
                text: 'Memproses...',
                disabled: true
            }

            this.setAllStateToFalse()
            try {
                const dispositionProp = await this.storeDisposition()
                
                if (this.verificationIdProp == null) {
                    // Update disposition is_done status
                    const finishProp = await this.finishDisposition()
                    // Post comments
                    let command = this.dispositionForm.input.command
                    command = (command !== null && command === '') ? ', '+ command : ''
                    let content = this.dispositionForm.input.content
                    let comment = content + command
                    const commentProp = await this.postComment(comment)
                } else {
                    this.stateFinishDispositionIsDone = true
                    this.statePostCommentIsDone = true
                }
            } catch (err) {
                console.log('Failed to store disposition')
                console.log(err)
                let errorMessage = (this.verificationIdProp != null)
                    ? 'Gagal menyimpan draft disposisi surat, harap menghubungi administrator'
                    : 'Gagal mengirimkan disposisi surat, harap menghubungi administrator'
                this.$toasted.error(errorMessage).goAway(3000)
                this.dispositionForm.submitBtn = {
                    loader: false,
                    text: 'Kirim',
                    disabled: false
                }
            }
        },
        setAllStateToFalse() {
            this.stateFinishDispositionIsDone = false
            this.statePostCommentIsDone = false
            this.stateStoreDispositionIsDone = false
        },
        checkIfShouldBeClose() {
            if(this.stateStoreDispositionIsDone && 
               this.stateFinishDispositionIsDone &&
               this.statePostCommentIsDone) {
                // Finishing
                let successMessage = (this.verificationIdProp != null)
                    ? 'Sukses menyimpan draft disposisi'
                    : 'Sukses mengirimkan disposisi surat'
                this.$toasted.success(successMessage).goAway(3000)
                this.closePopupModal(true)
               }
        },

        closePopupModal (refreshState = false) {
            // if (refreshState === true) {
            //     this.$emit('close-popup-modal', refreshState)
            //     return
            // }
            // Reset disabled receiver from dispositioned receiver list
            let dispositionedReceivers = this.dispositionForm.dispositionedReceivers
            if (dispositionedReceivers.length > 0) {
                for (let i = 0; i < dispositionedReceivers.length; i++) {
                    const item = dispositionedReceivers[i];
                    var disabledCheckbox = document.getElementById('position_'+ item)
                    if (disabledCheckbox)
                        disabledCheckbox.disabled = false
                }
            }
            this.dispositionForm.dispositionedReceivers = []
            // Reset input into default value
            this.dispositionForm.input = Object.assign({}, this.dispositionForm.defaultValue)
            this.dispositionForm.validate = {
                receivers: null,
                commands: null
            }
            this.dispositionForm.submitBtn = {
                loader: false,
                text: 'Kirim',
                disabled: true
            }
            this.$emit('close-popup-modal', refreshState)
        }
    }
}
</script>

<style scoped lang="scss">
.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
</style>